function mapInit() {
  var mapLocation = new google.maps.LatLng(-23.5698136, -46.6576053);

  var mapOptions = {
    zoom: 16,
    center: mapLocation,
    scrollwheel: false,
		styles: [ { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#e9e9e9" }, { "lightness": 17 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ] }, { "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ] } ]
  };

  var map = new google.maps.Map(document.getElementById('map-canvas'),
  mapOptions);

  var marker = new google.maps.Marker({
    map: map,
    draggable: false,
    animation: google.maps.Animation.DROP,
    position: mapLocation
  });
}

google.maps.event.addDomListener(window, 'load', mapInit);

jQuery(function() {
  var dropdowns = jQuery('#navigation, #mobilenavigation').children('.menu-item-has-children');

  if (dropdowns.length > 0) {
    jQuery.each(dropdowns, function(i, el) {
      var li = jQuery(el);
      var link = jQuery('> a:first-child', li);
      var toggle = jQuery('<span class="caret"></span>');

      link
        .addClass('dropdown-toggle')
        .attr('data-toggle', 'dropdown')
        .attr('role', 'button')
        .attr('aria-haspopup', 'true');

      link.addClass('has-dropdown');
      link.append(toggle);

      link.on('mouseover', function() {
        if (!jQuery(this).closest('li').hasClass('open')) {
          jQuery(this).dropdown('toggle');
        }
      });

      jQuery('ul.sub-menu', li).addClass('dropdown-menu');

      toggle.dropdown();
    });
  }

  jQuery('header.header').headroom();

  if(jQuery('#service') && jQuery(window).width() < 768) {
    jQuery('html, body').animate({ scrollTop: jQuery('#service').offset().top - 25 }, 'slow');
  }
});
